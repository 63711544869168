import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URI as string;

export const schoolApi = createApi({
  reducerPath: "schoolApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}schools/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token = state.school.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["School", "Schedule"],
  endpoints: (builder) => ({
    // Generate Password Reset Code
    passwordCode: builder.mutation({
      query: (email) => ({
        url: "/password-code",
        method: "POST",
        body: email,
      }),
    }),

    // Update Password with Reset Code
    updatePassword: builder.mutation({
      query: (data) => ({
        url: "/password-reset",
        method: "POST",
        body: data,
      }),
    }),

    // Login a School
    signInSchool: builder.mutation({
      query: (data) => {
        return {
          url: "school-signin/",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new school
    signupSchool: builder.mutation({
      query: (data) => {
        return {
          url: "school-signup",
          method: "POST",
          body: data,
        };
      },
    }),

    // Create a new student
    addStudent: builder.mutation({
      query: ({ schoolId, studentData }) => ({
        url: `${schoolId}/add-student`,
        method: "POST",
        body: studentData,
      }),
      invalidatesTags: ["School"],
    }),

    // Create a new activity
    addActivity: builder.mutation({
      query: ({ schoolId, activityData }) => ({
        url: `${schoolId}/create-activity`,
        method: "POST",
        body: activityData,
      }),
      invalidatesTags: ["School"],
    }),

    //Get all students
    getAllStudents: builder.query({
      query: (schoolId) => `${schoolId}/students`,
    }),

    // Get one student
    getStudentById: builder.query({
      query: ({ schoolId, studentId }) => `${schoolId}/students/${studentId}`,
    }),

    // Update student's genotype
    updateStudentGenotype: builder.mutation({
      query: ({ schoolId, studentId, genotype }) => ({
        url: `${schoolId}/students/${studentId}/genotype`,
        method: "PUT",
        body: { genotype },
      }),
      invalidatesTags: ["School"],
    }),

    // Get all activities of a school
    getAllActivities: builder.query({
      query: (schoolId) => `${schoolId}/activities`,
    }),

    // Get only inhouse activities of a school
    getAllInHouseActivities: builder.query({
      query: (schoolId) => `${schoolId}/inhouse-activities`,
    }),

    // Get all provider activities of a school
    getAllProviderActivities: builder.query({
      query: (schoolId) => `${schoolId}/provider-activities`,
    }),

    // Get a single activity by ID
    getActivityById: builder.query({
      query: ({ schoolId, activityId }) =>
        `${schoolId}/activities/${activityId}`,
    }),

    // Get all activities a student is enrolled to
    getAllStudentActivities: builder.query({
      query: ({ schoolId, studentId }) =>
        `${schoolId}/students/${studentId}/activities`,
    }),

    // Get a single activity by ID
    getActivityByProviderById: builder.query({
      query: ({ schoolId, activityId }) =>
        `${schoolId}/provider-activities/${activityId}`,
    }),

    // Add a student to an activity
    addStudentToActivity: builder.mutation({
      query: ({ schoolId, activityId, studentId }) => ({
        url: `${schoolId}/activities/${activityId}/add-student/${studentId}`,
        method: "POST",
      }),
      invalidatesTags: ["School"],
    }),

    //Get school data
    getOverview: builder.query({
      query: (schoolId) => `${schoolId}/overview`,
    }),

    // Add a parent to a student
    addParent: builder.mutation({
      query: ({ schoolId, studentId, parentId, parentData }) => {
        // Prepare the payload depending on whether parentId is provided or not
        const body = parentId
          ? { parentId }
          : parentData
          ? {
              firstName: parentData.firstName,
              lastName: parentData.lastName,
              email: parentData.email,
              phoneNo: parentData.phoneNo,
            }
          : null;

        return {
          url: `${schoolId}/students/${studentId}/add-parent`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["School"],
    }),

    // Get parents
    getAllParents: builder.query({
      query: () => "all-parents",
      providesTags: ["School"],
    }),

    // Get parents added by the school
    getParentsAddedBySchool: builder.query({
      query: (schoolId) => `${schoolId}/parents`,
    }),

    // Get one parent
    getOneParent: builder.query({
      query: (parentId) => `parents/${parentId}`,
    }),

    // Update a student's score in an activity
    updateStudentScore: builder.mutation({
      query: ({ schoolId, activityId, studentId, sessionData }) => ({
        url: `${schoolId}/students/${studentId}/activities/${activityId}/add-score`,
        method: "PUT",
        body: sessionData,
      }),
      invalidatesTags: ["School"],
    }),

    // Pay for school
    payForSchool: builder.mutation({
      query: ({ schoolId, studentsNo, price, term, year, paymentId }) => ({
        url: `/subscribe`,
        method: "PATCH",
        body: { schoolId, studentsNo, price, term, year, paymentId },
      }),
    }),

    // Get parents
    getPrice: builder.query({
      query: (id) => `payments/${id}`,
      providesTags: ["School"],
    }),

    // Get price overview
    getPriceOverview: builder.query({
      query: (id) => `payments-summary/${id}`,
      providesTags: ["School"],
    }),

    // Get total price
    getTotalPayment: builder.query({
      query: (schoolId) => `total-payment/${schoolId}`,
    }),

    // Mutation to add grading to an activity
    addGradingToActivity: builder.mutation({
      query: ({ schoolId, activityId, sessionNo }) => ({
        url: `${schoolId}/activities/${activityId}/add-grading`,
        method: "POST",
        body: { sessionNo },
      }),
      invalidatesTags: ["School"],
    }),

    // Get result of a student and activity
    getResult: builder.query({
      query: ({ schoolId, activityId }) =>
        `${schoolId}/activities/${activityId}/results`,
    }),

    // Get result of a student in an activity
    getStudentActivityResult: builder.query({
      query: ({ schoolId, activityId, studentId }) =>
        `${schoolId}/activities/${activityId}/students/${studentId}/results`,
    }),

    // Add this mutation inside the endpoints of schoolApi
    editStudent: builder.mutation({
      query: ({ schoolId, studentId, studentData }) => ({
        url: `${schoolId}/edit-student/${studentId}`,
        method: "PUT",
        body: studentData,
      }),
      invalidatesTags: ["School"],
    }),

    // Add this mutation inside the endpoints of schoolApi
    editParent: builder.mutation({
      query: ({ schoolId, parentId, parentData }) => ({
        url: `${schoolId}/edit-parent/${parentId}`,
        method: "PUT",
        body: parentData,
      }),
      invalidatesTags: ["School"],
    }),

    // Add schedule to activity
    scheduleActivity: builder.mutation({
      query: ({ schoolId, activityId, scheduleData }) => ({
        url: `${schoolId}/activities/${activityId}/add-schedule`,
        method: "POST",
        body: scheduleData,
      }),
      invalidatesTags: ["School"],
    }),

    // Mark activity register
    markRegister: builder.mutation({
      query: ({
        schoolId,
        activityId,
        scheduleId,
        studentId,
        registerData,
      }) => ({
        url: `${schoolId}/activities/${activityId}/schedule/${scheduleId}/students/${studentId}/mark-register`,
        method: "POST",
        body: registerData,
      }),
      invalidatesTags: ["School"],
    }),

    // Add this mutation inside the endpoints of schoolApi
    updateHeadOfSchool: builder.mutation({
      query: ({ schoolId, headData }) => ({
        url: `${schoolId}/head-of-school`,
        method: "PUT",
        body: headData,
      }),
      invalidatesTags: ["School"],
    }),

    // Add this mutation inside the endpoints of schoolApi
    addCoachToActivity: builder.mutation({
      query: ({ schoolId, activityId, headData }) => ({
        url: `${schoolId}/activities/${activityId}/coach`,
        method: "PATCH",
        body: headData,
      }),
      invalidatesTags: ["School"],
    }),

    // Get head of schooll
    getHeadOfSchool: builder.query({
      query: (schoolId) => `${schoolId}/head-of-school`,
    }),

    // Get activity coach
    getCoach: builder.query({
      query: ({ schoolId, activityId }) =>
        `${schoolId}/activities/${activityId}/coach`,
    }),

    //Get activities created by Providers
    getActivitiesByProviders: builder.query({
      query: () => "activities/marketplace",
    }),

    // Get activity coach
    getOneActivityByProvider: builder.query({
      query: (activityId) => `activities/${activityId}`,
    }),

    // Enrol school for activity
    addProviderActivityToSchool: builder.mutation({
      query: ({ schoolId, activityId }) => ({
        url: `${schoolId}/${activityId}/enroll`,
        method: "POST",
      }),
      invalidatesTags: ["School"],
    }),

    // Get activities by provider
    getAllActivitiesByProvider: builder.query({
      query: (schoolId) => `${schoolId}/enrolled-provider-activities`,
    }),

    // Get all schedules for a school
    getAllSchedules: builder.query({
      query: (schoolId) => `${schoolId}/schedules`,
      providesTags: ["Schedule"],
    }),

    // Get schedules for a specific activity in a school
    getActivitySchedules: builder.query({
      query: ({ schoolId, activityId }) =>
        `${schoolId}/activities/${activityId}/schedules`,
      providesTags: ["Schedule"],
    }),

    // Get a single schedule by ID
    getSingleSchedule: builder.query({
      query: ({ schoolId, activityId, scheduleId }) =>
        `${schoolId}/activities/${activityId}/schedules/${scheduleId}`,
      providesTags: ["Schedule"],
    }),

    // Define the create schedule mutation
    schoolCreateSchedule: builder.mutation({
      query: (scheduleData) => ({
        url: "/create-schedule",
        method: "POST",
        body: scheduleData,
      }),
      invalidatesTags: ["Schedule"],
    }),

    // Get provider activities for payments
    getProvidersForPayments: builder.query({
      query: (schoolId) => `school/${schoolId}/payments/provider-activities`,
      providesTags: ["School"],
    }),

    // Pay for provider
    payForProvider: builder.mutation({
      query: ({ schoolId, activityId, studentsNo }) => ({
        url: `/pay-for-provider-activity`,
        method: "POST",
        body: { schoolId, activityId, studentsNo },
      }),
    }),

    // Mutation for bulk student upload
    uploadBulkStudents: builder.mutation({
      query: ({ schoolId, file }) => {
        const formData = new FormData();
        formData.append("file", file);

        return {
          url: `${schoolId}/add-bulk-students`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: ["School"],
    }),

    downloadLetter: builder.query({
      query: (id) => ({
        url: `${id}/download-letter-pdf`,
        responseHandler: (response) => response.blob(),
      }),
    }),

    // Define the editSchool mutation
    editSchool: builder.mutation({
      query: ({ schoolId, updatedData }) => ({
        url: `${schoolId}/edit-school`,
        method: "PUT",
        body: updatedData,
      }),
      invalidatesTags: ["School"],
    }),

    // RTK Query mutation for adding multiple students to an activity
    addStudentsToActivity: builder.mutation({
      query: ({ schoolId, activityId, studentIds }) => ({
        url: `${schoolId}/activities/${activityId}/add-students`,
        method: "POST",
        body: { studentIds }, // Sending the array in the request body
      }),
      invalidatesTags: ["School"],
    }),

    // Remove a student from an activity
    removeStudentFromActivity: builder.mutation({
      query: ({ schoolId, activityId, studentId }) => ({
        url: `${schoolId}/activities/${activityId}/remove-student/${studentId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["School"],
    }),

    // ...
  }),
});

export const {
  usePasswordCodeMutation,
  useUpdatePasswordMutation,
  useSignInSchoolMutation,
  useSignupSchoolMutation,
  useAddActivityMutation,
  useAddStudentMutation,
  useGetAllStudentsQuery,
  useGetStudentByIdQuery,
  useUpdateStudentGenotypeMutation,
  useGetAllActivitiesQuery,
  useGetActivityByIdQuery,
  useAddStudentToActivityMutation,
  useGetOverviewQuery,
  useAddParentMutation,
  useGetAllParentsQuery,
  useGetParentsAddedBySchoolQuery,
  useGetOneParentQuery,
  useUpdateStudentScoreMutation,
  usePayForSchoolMutation,
  useGetPriceQuery,
  useGetTotalPaymentQuery,
  useAddGradingToActivityMutation,
  useGetResultQuery,
  useGetStudentActivityResultQuery,
  useGetPriceOverviewQuery,
  useEditStudentMutation,
  useEditParentMutation,
  useScheduleActivityMutation,
  useMarkRegisterMutation,
  useAddCoachToActivityMutation,
  useUpdateHeadOfSchoolMutation,
  useGetHeadOfSchoolQuery,
  useGetCoachQuery,
  useGetActivitiesByProvidersQuery,
  useGetOneActivityByProviderQuery,
  useAddProviderActivityToSchoolMutation,
  useGetAllActivitiesByProviderQuery,
  useGetAllInHouseActivitiesQuery,
  useGetAllProviderActivitiesQuery,
  useGetActivityByProviderByIdQuery,
  useGetAllStudentActivitiesQuery,
  useSchoolCreateScheduleMutation,
  useGetAllSchedulesQuery,
  useGetActivitySchedulesQuery,
  useGetSingleScheduleQuery,
  useGetProvidersForPaymentsQuery,
  usePayForProviderMutation,
  useUploadBulkStudentsMutation,
  useDownloadLetterQuery,
  useLazyDownloadLetterQuery,
  useEditSchoolMutation,
  useAddStudentsToActivityMutation,
  useRemoveStudentFromActivityMutation,
} = schoolApi;

import React, { useState } from "react";
import {
  useGetAllStudentsQuery,
  useAddStudentsToActivityMutation,
  useGetActivityByIdQuery,
} from "../../../app/services/schoolApi";
import { Button } from "../../FormElements";
import { useAppSelector } from "../../../app/hooks";
import { selectSchool } from "../../../app/features/schoolSlice";
import { colors, screens } from "../../../utils";
import { useParams } from "react-router-dom";
import { styled } from "styled-components";

type StudentOption = {
  value: string;
  label: string;
  studentClass: string;
};

const AddStudentsToActivity = () => {
  const { id: schoolId } = useAppSelector(selectSchool);
  const { id: activityId } = useParams();
  const { data: studentsData } = useGetAllStudentsQuery(schoolId);
  const [addStudentsToActivity] = useAddStudentsToActivityMutation();
  const {
    data: activityData,
    isLoading,
    isError,
    refetch,
  } = useGetActivityByIdQuery({
    activityId,
    schoolId,
  });
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  // Filter students that have already been added to the activity
  const addedStudentIds = activityData?.students || [];
  const availableStudents = studentsData?.filter(
    (student: any) => !addedStudentIds.includes(student._id)
  );

  // Map students for displaying in the selection list
  const studentOptions = availableStudents?.map((student: any) => ({
    value: student._id,
    label: `${student.fullName}`,
    studentClass: `${student.studentClass}`,
  }));

  const handleStudentSelection = (studentId: string) => {
    setSelectedStudents((prev) =>
      prev.includes(studentId)
        ? prev.filter((id) => id !== studentId)
        : [...prev, studentId]
    );
  };

  const handleAddStudents = async () => {
    if (selectedStudents.length > 0) {
      await addStudentsToActivity({
        schoolId,
        activityId,
        studentIds: selectedStudents,
      });
      setSelectedStudents([]); // Clear selected students after successful addition
      refetch(); // Refetch activity data to reflect updates if needed
    }
  };

  return (
    <Container>
      <Wrapper>
        <h3>Add Students to Activity</h3>
        <ItemWrapper>
          <strong>Title: </strong>
          <p>{activityData?.title}</p>
        </ItemWrapper>
        <StudentsWrapper>
          {studentOptions?.map((student: StudentOption) => (
            <Student key={student.value}>
              <StudentName>
                <input
                  type="checkbox"
                  checked={selectedStudents.includes(student.value)}
                  onChange={() => handleStudentSelection(student.value)}
                />
                <p>{student.label}</p>
              </StudentName>
              <p>
                {student.studentClass === "jss1"
                  ? "JSS 1"
                  : student.studentClass === "jss2"
                  ? "JSS 2"
                  : student.studentClass === "jss3"
                  ? "JSS 3"
                  : student.studentClass === "sss1"
                  ? "SS 1"
                  : student.studentClass === "sss2"
                  ? "SS 2"
                  : student.studentClass === "sss3"
                  ? "SS 3"
                  : student.studentClass === "primary1"
                  ? "Primary 1"
                  : student.studentClass === "primary2"
                  ? "Primary 2"
                  : student.studentClass === "primary3"
                  ? "Primary 3"
                  : student.studentClass === "primary4"
                  ? "Primary 4"
                  : student.studentClass === "primary5"
                  ? "Primary 5"
                  : student.studentClass === "primary6"
                  ? "Primary 6"
                  : null}
              </p>
            </Student>
          ))}
        </StudentsWrapper>
        <ButtonWrapper>
          <Button
            text="Add Selected Students"
            color={colors.white}
            backgroundColor={colors.primary}
            border="none"
            onClick={handleAddStudents}
            disabled={selectedStudents.length === 0}
          />
        </ButtonWrapper>
      </Wrapper>
    </Container>
  );
};

export default AddStudentsToActivity;

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 90%;
  margin: 2rem auto;

  h3 {
    font-size: 1.2rem;
  }

  small {
    font-size: 0.7rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const ItemWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;

  p {
    font-weight: 500;
    font-size: 0.85rem;
  }

  strong {
    font-size: 0.85rem;
    font-weight: 600;
    color: ${colors.primary};
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  width: fit-content;
`;

const StudentsWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: ${colors.lightBack};
  border-radius: 0.5rem;
  padding: 1rem;
  flex-direction: column;
  height: 60vh;
  overflow: scroll;
  gap: 0.5rem;

  h4 {
    font-size: 0.85rem;
  }
`;

const Student = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 50%;

  p {
    font-size: 0.85rem;
  }

  @media (max-width: ${screens.tab}) {
    width: 100%;
  }
`;

const StudentName = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
`;

import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { partnerApi } from "./services/partnerApi";
import partnerReducer from "./features/partnerSlice";
import { providerApi } from "./services/providerApi";
import providerReducer from "./features/providerSlice";
import { schoolApi } from "./services/schoolApi";
import { learningApi } from "./services/learningApi";
import schoolReducer from "./features/schoolSlice";
import { adminApi } from "./services/adminApi";
import adminReducer from "./features/adminSlice";
import { parentApi } from "./services/parentApi";
import parentReducer from "./features/parentSlice";
import { universityApi } from "./services/universityApi";
import universityReducer from "./features/universitySlice";
import { meetingApi } from "./services/meetingApi";

export const store = configureStore({
  reducer: {
    partner: partnerReducer,
    provider: providerReducer,
    school: schoolReducer,
    admin: adminReducer,
    parent: parentReducer,
    university: universityReducer,
    [partnerApi.reducerPath]: partnerApi.reducer,
    [providerApi.reducerPath]: providerApi.reducer,
    [schoolApi.reducerPath]: schoolApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [learningApi.reducerPath]: learningApi.reducer,
    [parentApi.reducerPath]: parentApi.reducer,
    [universityApi.reducerPath]: universityApi.reducer,
    [meetingApi.reducerPath]: meetingApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(partnerApi.middleware)
      .concat(providerApi.middleware)
      .concat(schoolApi.middleware)
      .concat(adminApi.middleware)
      .concat(learningApi.middleware)
      .concat(parentApi.middleware)
      .concat(universityApi.middleware)
      .concat(meetingApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

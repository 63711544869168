import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store";

const url = process.env.REACT_APP_BASE_URL as string;

export const meetingApi = createApi({
  reducerPath: "meetingApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${url}meetings/`,

    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const token =
        state.admin.token ||
        state.provider.token ||
        state.parent.token ||
        state.school.token;

      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["Meeting"],
  endpoints: (builder) => ({
    // Create Meetings
    createMeeting: builder.mutation({
      query: (meetingData) => ({
        url: "create",
        method: "POST",
        body: meetingData,
      }),
    }),

    // Host Meeting
    hostMeeting: builder.mutation({
      query: (meetingId) => ({
        url: `host/${meetingId}`,
        method: "POST",
      }),
    }),

    // ...
  }),
});

export const { useCreateMeetingMutation, useHostMeetingMutation } = meetingApi;
